.layout {
    width: 100%;
    height: 100%;
    background-color: #000;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;
    overflow: auto;
  }
  .group {
    display: flex;
    flex-direction: column;
    
    width: 50rem;
    height: 100rem;
    max-height: 100rem;
    padding: 16px;
    box-sizing: border-box;
    overflow: auto;
  }
  .selected {
    position: absolute;
    top: 0;
    left: 0;
    width: 90%;
    height: 90%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    }
  .grow1 { flex-grow: 1; }
  .marginLeft { margin-left: auto; }